import * as React from "react"

import LayoutUpdate from "../components/layout-update"
import Seo from "../components/seo"
import HeroHomeUpdateNew from "../components/sections/hero-home-update-new"
import LayoutUpdateWithAnnouncementBar from "../components/layout-update-with-announcement-bar"

const DemoPageWithAnnouncementBar = () => (
  <LayoutUpdateWithAnnouncementBar>
    <Seo
      title="SRA 831(b) for Business Owners"
      description="SRA has made it possible for any successful business to take advantage of the same tools previously reserved for Fortune 500 Companies and utilize an 831(b) Plan to cover uncommon, underinsured or uninsured risks with tax-deferred funds."
      noIndex
    />

    <HeroHomeUpdateNew />
  </LayoutUpdateWithAnnouncementBar>
)

export default DemoPageWithAnnouncementBar
